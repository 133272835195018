import { ready } from '@/store/utils'

export default {
  complaintTypes: (state) => ({
    ...state.complaintTypes,
    ready: ready(state.complaintTypes),
  }),
  complaint: (state) => ({
    ...state.complaint,
  }),
  questionComplaints: (state) => {
    if (state.questionComplaints.data.complaintFilter) {
      const { complaintFilter } = state.questionComplaints.data

      return {
        ...state.questionComplaints,
        data: {
          ...state.questionComplaints.data,
          complaintFilter: [
            {
              complaintType: 'all',
              quantity: complaintFilter.length,
            },
            ...complaintFilter,
          ],
        },
      }
    }

    return {
      ...state.questionComplaints,
    }
  },
}
