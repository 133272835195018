import accountApi from '@/service/account'
import cookies from '@/service/cookies'
import { PROFILES, STAGES, containsUserProfile } from '@/service/profiles'
import * as types from './types'

export default {
  getUser: async (
    { commit, dispatch },
    token = cookies.getItem('token') || null
  ) => {
    commit(types.SET_USER_LOADING, true)
    if (token) {
      try {
        const userResponse = await accountApi.getUser()
        const user = userResponse.data
        const signinResponse = await accountApi.getSignedIn()
        const signin = signinResponse.data

        const isMiddleSchoolStudent = (
          containsUserProfile(user.profiles, PROFILES.STUDENT)
          && signin === STAGES.MIDDLE_SCHOOL
        )
        const isHighSchoolStudent = (
          containsUserProfile(user.profiles, PROFILES.STUDENT)
            && signin === STAGES.HIGH_SCHOOL
        )
        const isStudent = isMiddleSchoolStudent || isHighSchoolStudent

        const isTeacher = containsUserProfile(user.profiles, PROFILES.TEACHER)
        const isSchool = containsUserProfile(user.profiles, PROFILES.SCHOOL)
        const isManager = containsUserProfile(user.profiles, PROFILES.MANAGER)

        if (!isStudent) {
          dispatch('getReallocationDateLimit')
        }

        commit(types.SET_USER, {
          user: {
            ...user,
            isMiddleSchoolStudent,
            isHighSchoolStudent,
            isStudent,
            isTeacher,
            isSchool,
            isManager,
          },
        })
        commit(types.SET_USER_LOADING, false)

        return user
      } catch (error) {
        console.error(error)
      }
    }
    commit(types.SET_USER_LOADING, false)

    return false
  },
  logout: ({ commit }) => {
    cookies.removeItem('token')
    commit(types.CLEAR_ALL)
  },
  getReallocationDateLimit: async ({ commit }) => {
    commit(types.REALLOCATION_DATE_LIMIT, { loading: true })
    try {
      const response = await accountApi.getReallocationDateLimit()

      commit(types.REALLOCATION_DATE_LIMIT, {
        loading: false,
        data: response.data,
      })
    } catch (error) {
      commit(types.REALLOCATION_DATE_LIMIT, {
        loading: false,
        error,
      })
    }
  },
  setYearSelectedContent: ({ commit }, year) => {
    commit(types.SET_YEAR_SELECTED_CONTENT, year)
  },
}
