import { ready } from '@/store/utils'

export default {
  assignment: (state) => ({
    ...state.assignment,
    ready: ready(state.assignment),
  }),
  report: (state) => ({
    ...state.report,
    ready: ready(state.report),
  }),
  redo: (state) => ({
    ...state.redo,
    ready: ready(state.redo),
  }),
}
