import cookies from '@/service/cookies'
import loginAndReturn from '@/utils/loginAndReturn'
import storage from '@/service/storage'
import router from '@/router'

const authorizationInterceptor = (config) => {
  const authorization = `${cookies.getItem('token')}`
  config.headers.authorization = authorization

  return config
}

const responseErrorHandler = (error) => {
  if (!error.response && error.message === 'Network Error') {
    return Promise.reject(new Error({
      ...error,
      response: { status: 404 },
    }))
  }
  const { status, config } = error.response

  const is401Error = status === 401
  const is404Error = status === 404
  const is403Error = status === 403
  const is422Error = status === 422
  const isActivities = storage.getItem('domain') === 'activities'
  const isStatisticsEndpoint = /statistics$/.test(config.url)
  const isEnterAssignmentEndpoint = /\/questionnaires\/.+\/(assignments|questions)$/.test(config.url)
  const isContinueAssignmentEndpoint = /\/assignments\/(.+\/(answers|questions\/.+)|\d+)$/.test(config.url)
  const isReportEndpoint = /\/questionnaires\/.+\/reports$/.test(config.url)
  const isProposedAssignmentInfoEndpoint = /\/subjects\/.+\/proposed-assignment-info$/.test(config.url)

  if (is401Error) {
    cookies.removeItem('token')
    loginAndReturn()
  } else if (
    !isStatisticsEndpoint
    && !(
      isActivities && (
        (isEnterAssignmentEndpoint && is404Error)
        || (isContinueAssignmentEndpoint && is422Error)
        || (isReportEndpoint && is422Error)
        || (isProposedAssignmentInfoEndpoint && is403Error)
      )
    )
  ) {
    router.push({ name: 'error', params: { status } })
  }

  return Promise.reject(error)
}

const responseErrorHandlerInterceptor = new Promise((response) => response, responseErrorHandler)

export {
  authorizationInterceptor,
  responseErrorHandlerInterceptor,
}
