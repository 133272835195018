/* eslint-disable no-param-reassign */
import Vue from 'vue'
import VueGtm from 'vue-gtm'

import router from '@/router'

Vue.use(VueGtm, {
  id: process.env.VUE_APP_TRACKING_GTM_ID,
  enabled: JSON.parse(process.env.VUE_APP_TRACKING_ENABLED),
  debug: process.env.VUE_APP_MODE !== 'production',
  loadScript: false, // is being loaded in `/public/index.html`
  vueRouter: router,
})

Vue.prototype.$trackEvent = ({
  ...rest
}) => {
  Vue.gtm.trackEvent({
    event: 'custom_event_analytics',
    ...rest,
  })
}

Vue.prototype.$track = {
  category: {
    feedback: 'Feedback',
    proposedQuestions: 'QuestoesSugeridas',
    syllabus: 'ExerciciosPersonalizados',
    extraChapterBookActivities: 'AtividadesDoLivroEExtrasCapitulo',
    yearlyEnemPractice: 'PraticandoOEnemPorAno',
    difficultyEnemPractice: 'PraticandoOEnemPorDificuldade',
  },
  action: {
    rateExperience: 'AvaliarExperiencia',
    reinforce: 'Reforcar',
    finish: 'FinalizarAtividade',
    syllabus: {
      extraActivitiesClick: 'ClickAtividadesExercíciosPersonalizados',
    },
    finishedChapter: 'FinalizarCapitulo',
    pausedChapter: 'CapituloPausado',
    reviewChapter: 'RevisarCapitulo',
    seeReport: 'VerRelatorio',
    enemPratice: 'PraticarEnem',
    finishedSubject: 'FinalizouDisciplina',
  },
  labels: {},
}
