import questionsApi from '@/service/questions'
import * as types from './types'
import * as assignmentTypes from '../assignments/types'

const resetReviewVideo = (commit) => {
  commit(types.SET_VIDEO, {
    loading: false,
    data: {},
    error: null,
  })
}
const resetPageFlip = (commit) => {
  commit(types.SET_PAGEFLIP, {
    loading: false,
    data: {},
    error: null,
  })
}

export default {
  getQuestion: ({ commit }, params = {}) => {
    commit(types.SET_QUESTION, { loading: true, data: {}, error: null })
    commit(types.SET_ANSWER, { loading: false, data: {}, error: null })

    return questionsApi.fetchQuestion(params)
      .then((response) => {
        commit(types.SET_QUESTION, {
          data: response.data,
          loading: false,
        })
        commit(assignmentTypes.SET_ASSIGNMENT, {
          data: {
            title: response.data.assignment.title,
            subtitle: response.data.assignment.subtitle,
          },
        })

        if (response.data.answer) {
          commit(types.SET_ANSWER, { data: response.data.answer })
        }

        if (response.data.performance) {
          commit(types.SET_PERFORMANCE, { data: response.data.performance })
        }
      })
      .catch((error) => {
        commit(types.SET_QUESTION, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  clearQuestion: ({ commit }) => {
    commit(types.CLEAR_QUESTION)
  },
  getQuestions: ({ commit }, { questionnaireCode }) => {
    commit(types.SET_QUESTIONS, { loading: true, data: null, error: null })

    return questionsApi.fetchQuestions({ questionnaireCode })
      .then((response) => {
        commit(types.SET_QUESTIONS, {
          data: response,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_QUESTIONS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  setQuestions: ({ commit }, { questions }) => {
    commit(types.SET_QUESTIONS, { data: questions })
  },
  sendAnswer: ({ commit }, {
    assignmentCode,
    questionId,
    markedOption,
  }) => {
    commit(types.SET_ANSWER, { loading: true, data: {} })

    return questionsApi.postAnswer({
      assignmentCode,
      questionId,
      markedOption,
    })
      .then((response) => {
        commit(types.SET_ANSWER, {
          data: response.answer,
          loading: false,
          error: null,
        })
      })
      .catch((error) => {
        commit(types.SET_ANSWER, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getPageFlip: async ({ commit }, questionnaireCode) => {
    resetPageFlip(commit)
    commit(types.SET_PAGEFLIP, { loading: true })

    return questionsApi.fetchPageFlips(questionnaireCode)
      .then((response) => {
        if (
          response === undefined
          || !response.contentPath
        ) {
          commit(types.SET_PAGEFLIP, {
            error: true,
            loading: false,
          })

          return Promise.reject()
        }
        commit(types.SET_PAGEFLIP, {
          data: response,
          loading: false,
        })

        return response
      })
      .catch((error) => {
        commit(types.SET_PAGEFLIP, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getVideo: ({ commit }, questionnaireCode) => {
    resetReviewVideo(commit)
    commit(types.SET_VIDEO, { loading: true })

    return questionsApi.fetchVideo(questionnaireCode)
      .then((response) => {
        commit(types.SET_VIDEO, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_VIDEO, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  resetVideo: async ({ commit }) => {
    resetReviewVideo(commit)
  },
  resetPageFlip: async ({ commit }) => {
    resetPageFlip(commit)
  },
}
