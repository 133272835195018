import Vue from 'vue'
import Router from 'vue-router'
import cookies from '@/service/cookies'
import loginAndReturn from '@/utils/loginAndReturn'

const HeaderLayout = () => import('@/views/HeaderLayout')
const Home = () => import('@/views/Home/Home')
const Lecture = () => import('@/views/Lecture/Lecture')
const Subject = () => import('@/views/Subject/Subject')
const ExtraActivity = () => import('@/views/ExtraActivity/ExtraActivity')
const Assignment = () => import('@/views/Assignment/Assignment')
const SearchProposedAssignment = () => import('@/views/SearchProposedAssignment')
const ProposedAssignmentHistoric = () => import('@/views/ProposedAssignmentHistoric')
const Report = () => import('@/views/Report/Report')
const ExtraActivityContextWrapper = () => import('@/components/ExtraActivityContextWrapper')
const BaseLayout = () => import('@/views/BaseLayout')
const PageError = () => import('@/views/PageError')

if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/error/:status',
      name: 'error',
      component: PageError,
    },
    {
      path: '/',
      component: BaseLayout,
      children: [
        {
          path: 'atividades',
          component: HeaderLayout,
          children: [
            {
              path: '',
              name: 'home',
              component: Home,
            },
            {
              path: 'subjects/:subjectId',
              name: 'subject',
              component: Subject,
              children: [
                {
                  path: 'lectures/:lectureId',
                  name: 'lecture-assignments',
                  props: (route) => ({
                    chapterId: parseInt(route.query.chapterId, 10) || null,
                    subjectId: parseInt(route.params.subjectId, 10),
                    lectureId: parseInt(route.params.lectureId, 10),
                  }),
                  component: Lecture,
                },
              ],
            },
            {
              path: 'subjects/:subjectId/lectures/:lectureId/proposed-assignment',
              name: 'search-proposed-assignments',
              props: { questionBarLightMode: true },
              component: SearchProposedAssignment,
            },
            {
              path: 'subjects/:subjectId/lectures/:lectureId/historic',
              name: 'proposed-assignment-historic',
              component: ProposedAssignmentHistoric,
            },
            {
              path: 'subjects/:subjectId/lectures/:lectureId/questionnaires/:questionnaireCode/question',
              name: 'lecture-question',
              props: (route) => ({
                questionBarLightMode: true,
                lectureId: parseInt(route.params.lectureId, 10),
              }),
              component: Assignment,
            },
            {
              path: 'subjects/:subjectId/lectures/:lectureId/questionnaires/:questionnaireCode/report',
              name: 'lecture-report',
              component: Report,
            },
            {
              path: 'extra-activities/:extraActivityId',
              name: 'extra-activity-assignments',
              component: ExtraActivity,
              children: [
                {
                  path: 'by/:context',
                  name: 'extra-activities-by-context',
                  component: ExtraActivityContextWrapper,
                },
              ],
            },
            {
              path: 'extra-activities/:extraActivityId/questionnaires/:questionnaireCode/question',
              name: 'extra-activity-question',
              component: Assignment,
            },
            {
              path: 'extra-activities/:extraActivityId/questionnaires/:questionnaireCode/report',
              name: 'extra-activity-report',
              component: Report,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      redirect: {
        name: 'error',
        params: { status: 404 },
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }

    return null
  },
})

router.beforeEach((to, from, next) => {
  const token = cookies.getItem('token')

  if (to.name === 'error') {
    next()
  } else if (!token || token.length === 0) {
    loginAndReturn(`${window.location.origin}${to.fullPath}`)
  } else {
    next()
  }
})

export default router
