import { ready } from '@/store/utils'

export default {
  question: (state) => ({
    ...state.question,
    ready: ready(state.question),
  }),
  questions: (state) => ({
    ...state.questions,
    ready: ready(state.questions),
  }),
  answer: (state) => ({
    ...state.answer,
    ready: ready(state.answer),
  }),
  performance: (state) => ({
    ...state.performance,
    ready: ready(state.performance),
  }),
  rewards: (state) => ({
    ...state.rewards,
    ready: ready(state.rewards),
  }),
  progress: (state) => ({
    ...state.progress,
    ready: ready(state.progress),
  }),
  tip: (state) => ({
    ...state.tip,
    ready: ready(state.tip),
  }),
  video: (state) => ({
    ...state.video,
    ready: ready(state.video),
  }),
  pageflip: (state) => ({
    ...state.pageflip,
    ready: ready(state.pageflip),
  }),
}
