import http from '@/service/http'
import * as types from './types'

export default {
  setAssignment: ({ commit }, assignment) => {
    commit(types.SET_ASSIGNMENT, { data: assignment })
  },
  finishAssignment: ({ commit }, assignmentCode) => {
    commit(types.SET_REPORT, { loading: true })

    return http.put(`/assignments/${assignmentCode}`, {
      status: 'finished',
    })
      .catch((error) => {
        commit(types.SET_REPORT, {
          loading: false,
          error: error.response || true,
        })

        return Promise.reject(error)
      })
  },
  getReport: ({ commit }, questionnaireCode) => {
    commit(types.SET_REPORT, {
      data: null,
      loading: true,
      error: null,
    })

    return http.get(`/questionnaires/${questionnaireCode}/reports`)
      .then((response) => {
        commit(types.SET_REPORT, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_REPORT, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  clearReport: ({ commit }) => {
    commit(types.CLEAR_REPORT)
  },
  redoAssignment: ({ commit }, { assignmentCode }) => {
    commit(types.SET_REDO, { loading: true })

    return http.post(`/assignments/${assignmentCode}/redo`)
      .then(() => {
        commit(types.SET_REDO, { loading: false })
      })
      .catch((error) => {
        commit(types.SET_REDO, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
}
