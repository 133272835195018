import * as types from './types'

export default {
  [types.SET_QUESTION](state, question) {
    state.question = {
      ...state.question,
      ...question,
    }
  },
  [types.SET_QUESTIONS](state, questions) {
    state.questions = {
      ...state.questions,
      ...questions,
    }
  },
  [types.SET_PROGRESS](state, progress) {
    state.progress = {
      ...state.progress,
      ...progress,
    }
  },
  [types.SET_ANSWER](state, answer) {
    state.answer = {
      ...state.answer,
      ...answer,
    }
  },
  [types.SET_PERFORMANCE](state, performance) {
    state.performance = {
      ...state.performance,
      ...performance,
    }
  },
  [types.SET_REWARDS](state, rewards) {
    state.rewards = {
      ...state.rewards,
      ...rewards,
    }
  },
  [types.SET_TIP](state, tip) {
    state.tip = {
      ...state.tip,
      ...tip,
    }
  },
  [types.SET_VIDEO](state, video) {
    state.video = {
      ...state.video,
      ...video,
    }
  },
  [types.SET_PAGEFLIP](state, pageflip) {
    state.pageflip = {
      ...state.pageflip,
      ...pageflip,
    }
  },
  [types.CLEAR_QUESTION](state) {
    state.question = {
      loading: false,
      data: {},
      error: null,
    }
    state.answer = {
      loading: false,
      data: {},
      error: null,
    }
    state.progress = {
      loading: false,
      data: {},
      error: null,
    }
    state.performance = {
      loading: false,
      data: {},
      error: null,
    }
    state.rewards = {
      loading: false,
      data: null,
      error: null,
    }
  },
}
