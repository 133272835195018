export const SET_QUESTION = 'SET_QUESTION'
export const SET_QUESTIONS = 'SET_QUESTIONS'
export const SET_ANSWER = 'SET_ANSWER'
export const SET_PERFORMANCE = 'SET_PERFORMANCE'
export const SET_REWARDS = 'SET_REWARDS'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_TIP = 'SET_TIP'
export const SET_VIDEO = 'SET_VIDEO'
export const SET_PAGEFLIP = 'SET_PAGEFLIP'
export const CLEAR_QUESTION = 'CLEAR_QUESTION'
