import { ready } from '@/store/utils'

export default {
  subjects: (state) => ({
    ...state.subjects,
    ready: ready(state.subjects),
  }),
  subject: (state) => ({
    ...state.subject,
    ready: ready(state.subject),
  }),
  subjectLectures: (state) => ({
    ...state.subjectLectures,
    ready: ready(state.subjectLectures),
  }),
}
