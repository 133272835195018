<template>
  <div id="app">
    <router-view
      v-if="user.id"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import accountApi from '@/service/account'
import storage from '@/service/storage'
import typeform from '@/service/typeform'

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'user',
    ]),
  },
  watch: {
    user() {
      if (this.user.isStudent) {
        if (JSON.parse(process.env.VUE_APP_TRACKING_ENABLED)) {
          this.pushUserToDataLayer()
        }
        if (this.$route.path === '/') {
          this.$router.push({ name: 'home', replace: true })
        }
      } else {
        this.$router.push({
          name: 'error',
          params: { status: 403 },
          replace: true,
        })
      }
    },
  },
  async created() {
    await this.getUser()
    this.setupApplication()

    typeform.install({
      onReady: this.setSurveyButtonVisible(true),
    })
  },
  methods: {
    ...mapActions([
      'getUser',
    ]),
    ...mapActions('typeform', [ 'setTypeformReady' ]),
    setupApplication() {
      storage.setItem('domain', 'activities')
    },
    async pushUserToDataLayer() {
      const {
        id: accountId,
        school,
        profiles,
        grades,
      } = this.user
      const { data: personalData } = await accountApi.getPersonalData()

      window.dataLayer.push({
        accountId,
        schoolId: school.id,
        profileId: profiles[0].id,
        gradeId: grades.map(({ id }) => id),
        prospection: personalData.schoolProspection,
      })
    },
    setSurveyButtonVisible(value) {
      this.setTypeformReady(value)
    },
  },
}
</script>

<style lang="scss">
@import '~@sas-te/alfabeto-vue';
@import '~@sas-te/alfabeto-vue/src/scss/packages/vue-toasted';
</style>
