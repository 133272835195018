import Vue from 'vue'
import Vuex from 'vuex'

import account from '@/store/account'
import subjects from '@/store/subjects'
import lectures from '@/store/lectures'
import extraActivities from '@/store/extraActivities'
import questions from '@/store/questions'
import assignments from '@/store/assignments'
import complaints from '@/store/complaints'
import typeform from './typeform'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    subjects,
    lectures,
    extraActivities,
    questions,
    assignments,
    complaints,
    typeform,
  },
  strict: process.env.VUE_APP_MODE !== 'production',
})
