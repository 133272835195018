import { ready } from '@/store/utils'

export default {
  extraActivities: (state) => ({
    ...state.extraActivities,
    ready: ready(state.extraActivities),
  }),
  extraActivity: (state) => ({
    ...state.extraActivity,
    ready: ready(state.extraActivity),
  }),
  extraActivityProperties: (state) => ({
    ...state.extraActivityProperties,
    ready: ready(state.extraActivityProperties),
  }),
  extraActivityAssignments: (state) => ({
    ...state.extraActivityAssignments,
    ready: ready(state.extraActivityAssignments),
  }),
  extraActivitiesDifficulties: (state) => ({
    ...state.extraActivitiesDifficulties,
    data: state.extraActivitiesDifficulties.data
      .map((difficulty) => ({
        name: difficulty.text,
        id: difficulty.value,
        type: 'difficulty_level',
      })),
    ready: ready(state.extraActivitiesDifficulties),
  }),
}
