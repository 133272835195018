import axios from 'axios'

import urls from '@/utils/urls'
import { authorizationInterceptor, responseErrorHandlerInterceptor } from './http-interceptors'

const http = axios.create({
  baseURL: urls.ASSIGNMENTS_API,
  timeout: process.env.VUE_APP_TIMEOUT,
})

http.interceptors.request.use(authorizationInterceptor)

http.interceptors.response.use(responseErrorHandlerInterceptor)

export default http
