import Viewer from 'v-viewer'
import Vue from 'vue'

Vue.use(Viewer, {
  defaultOptions: {
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: true,
    tooltip: false,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: false,
    keyboard: true,
  },
})
