const production = {
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  ASSESSMENTS_INSIGHTS_URL: 'https://assessments-insights.portalsaseducacao.com.br/exercicios-personalizados',
  CQ_URI: 'https://centraldequestoes.portalsaseducacao.com.br',
  BASE_URL: 'https://assignments.portalsaseducacao.com.br',
  ASSESSMENTS_INSIGHTS_BASE_URL: 'https://assessments-insights.portalsaseducacao.com.br/exercicios-personalizados',
  // SAS DIGITAL
  ASSIGNMENTS_API: 'https://assignment-backend.sasdigital.com.br',
  AUTH_API: 'https://auth-server.sasdigital.com.br/v1',
  AUTH_API_V2: 'https://auth-server.sasdigital.com.br/v2',
  ASSESSMENTS_INSIGHTS_API: 'https://sas-assignment-bff.sasdigital.com.br',
}

const staging = {
  PORTAL_URL: 'https://portal.staging.portalsas.com.br',
  ASSESSMENTS_INSIGHTS_URL: 'https://assessments-insights.staging.portalsaseducacao.com.br/exercicios-personalizados',
  CQ_URI: 'https://centraldequestoes.staging.portalsaseducacao.com.br',
  BASE_URL: 'https://assignments.staging.portalsaseducacao.com.br',
  ASSESSMENTS_INSIGHTS_BASE_URL: 'https://assessments-insights.staging.portalsaseducacao.com.br/exercicios-personalizados',
  // SAS DIGITAL
  ASSIGNMENTS_API: 'https://assignment-backend.staging.sasdigital.com.br',
  AUTH_API: 'https://auth-server.staging.sasdigital.com.br/v1',
  AUTH_API_V2: 'https://auth-server.staging.sasdigital.com.br/v2',
  ASSESSMENTS_INSIGHTS_API: 'https://sas-assignment-bff.staging.sasdigital.com.br',
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:8080',
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.VUE_APP_MODE]

export default urls
