/* eslint-disable max-len */
const ptBR = {
  app: {
    title: 'Tarefa Online',
  },
  commons: {
    start: 'Iniciar',
    resume: 'Continuar',
    finished: 'Finalizado',
    reinforce: 'Reforçar',
    seeReport: 'Ver relatório',
    back: 'Voltar',
    historic: 'Histórico',
    close: 'Fechar',
    delete: 'Excluir',
    tryAgain: 'Tentar novamente',
    explanation: 'Explicação',
    export: 'Exportar',
    finish: 'Finalizar',
    previous: 'Anterior',
    next: {
      female: 'Próxima',
      male: 'Próximo',
    },
    chapter: 'Capítulo {number}',
    moreDetails: 'Mais detalhes',
    lessDetails: 'Menos detalhes',
    details: 'Detalhes',
    yes: 'Sim',
    no: 'Não',
    ok: 'OK',
    skipIntro: 'Pular introdução',
    new: 'Novo',
    error: 'Erro',
    studentsCount: '{count} aluno | {count} alunos',
    extraActivities: 'Atividades extras',
    extraActivitiesTabs: {
      byYear: 'Por ano',
      byDifficulty: 'Por dificuldade',
    },
    schoolYear: 'Ano letivo',
  },
  lectures: {
    science: 'Ciências',
    math: 'Matemática',
    portuguese: 'Português',
    physics: 'Física',
    chemistry: 'Química',
    biology: 'Biologia',
  },
  shifts: {
    morning: 'Manhã',
    afternoon: 'Tarde',
    evening: 'Noite',
    fulltime: 'Integral',
  },
  levels: {
    easy: 'Fácil',
    medium: 'Médio',
    hard: 'Difícil',
  },
  assignments: {
    releasedOn: 'Disponível em {date}',
    empty: {
      title: 'As tarefas estão a caminho!',
      description: 'Em breve serão exibidas aqui.',
      error: {
        title: 'Ocorreu um erro ao mostrar as tarefas da disciplina',
        description: 'Por favor, tente novamente',
      },
    },
  },
  searchProposedAssignment: {
    subtitle: 'Reforço de dificuldades',
    empty: {
      title: 'Escolhendo as melhores questões para você...',
    },
  },
  proposedAssignmentHistoric: {
    subtitle: 'Histórico de reforço',
  },
  questions: {
    online: 'Você está online novamente. Agora você poderá responder a sua tarefa.',
    offline: 'Você está offline. Para continuar respondendo a tarefa, é preciso estar conectado à internet.',
    progress: 'Questão {current} de {total}',
    questionNumber: 'Questão {current}',
    empty: {
      error: {
        title: 'Ops, não conseguimos mostrar essa questão...',
        description: 'Ocorreu um problema ao tentar carregar a questão, por favor tente novamente.',
      },
      404: {
        title: 'Ops! Não existem questões disponíveis para essa atividade.',
        description: 'Que tal praticar outra disciplina?',
      },
    },
    actions: {
      answer: 'Responder',
      correct: 'Resposta correta',
      incorrect: 'Resposta incorreta',
      finish: 'Finalizar',
      review: 'Revisar capítulo',
      tips: 'Ver dica',
      tip: 'Dica',
      congratulation: 'Parabéns',
      ops: 'Não foi dessa vez... mas não desista',
    },
    reportAProblem: {
      buttonLabel: 'Reportar problema',
      title: 'Reportar problema com questão',
      subtitle: 'Qual o problema?',
      describeProblem: 'Descreva o problema',
      send: 'Enviar',
      problemReported: 'Problema reportado',
      youReportedAProblem: 'Você reportou um problema',
      youReported: 'Você reportou',
      studentsReportedAProblem: 'Foi reportado por seus alunos problema nas questões.',
      reasons: 'Motivos',
      complaintTypes: {
        all: 'Todos',
        orthographic_error: 'Erro ortográfico',
        list_of_answers_error: 'Gabarito incorreto',
        image_problems: 'Problema com imagem',
        others: 'Outros',
      },
      errorState: {
        title: 'Ocorreu um erro ao tentar reportar. Por favor tente novamente.',
      },
      successState: {
        title: 'O problema foi reportado. Obrigado pelo seu feedback!',
      },
    },
    drawer: {
      title: 'Vídeo aula',
      helper: 'Ainda precisa de ajuda?',
      getBook: 'Consultar livro',
      empty: {
        title: {
          review: 'Ops... não conseguimos mostrar esse conteúdo',
        },
        description: {
          review: 'Ocorreu um erro enquanto carregamos o vídeo do capítulo. Por favor, Tente novamente.',
        },
      },
      pageflip: {
        toast: {
          error: {
            message: 'Não conseguimos carregar seu livro. Por favor, tente novamente mais tarde.',
          },
        },
      },
    },
  },
  studentReport: {
    info: 'Seu desempenho em',
    back: 'Voltar para capítulos',
    redo: 'Refazer tarefa',
    assignmentHits: 'Acertos na tarefa',
    hitsCount: '{count} de {total} questão | {count} de {total} questões',
    rightAnswers: 'acerto | acertos',
    wrongAnswers: 'erro | erros',
    problemReported: 'Problema reportado',
    allQuestions: 'Todas as questões',
    confirm: {
      text: 'Tem certeza de que deseja refazer a tarefa?',
      ok: 'Refazer',
      cancel: 'Cancelar',
    },
    empty: {
      title: 'Ops, não conseguimos preparar seu relatório... ',
      description: 'Ocorreu um problema ao preparar seu relatório. Por favor, tente novamente.',
    },
  },
  highSchool: {
    home: {
      title: 'Atividades do livro e extras',
      subtitle: 'Melhore o seu desempenho realizando atividades! ⚡✍',
      practiceBySubject: 'Pratique por disciplina',
      empty: {
        title: 'As tarefas estão a caminho!',
        description: 'Em breve serão exibidas aqui.',
        error: {
          title: 'Ocorreu um erro ao mostrar as disciplinas',
          description: 'Por favor, tente novamente',
        },
      },
    },
    subject: {
      bookAssignmentsSection: {
        title: 'Tarefa On-line',
        subtitle: 'Pratique as atividades do livro!',
      },
      schoolAssignmentsSection: {
        title: 'Atividades da escola',
        empty: {
          title: 'Nenhuma atividade aqui ainda 😥',
          description: 'Parece que seu professor ainda não criou atividades de {lecture} para a sua turma',
        },
      },
      proposedAssignmentsSection: {
        title: 'Melhore seu desempenho',
        subtitle: 'Pratique com questões selecionadas especialmente para você reforçar seus pontos fracos.',
      },
      historicAssignmentsSection: {
        title: 'Histórico por atividade',
        subtitle: 'Aqui você consegue acompanhar o desempenho dos esforços realizados.',
      },
    },
    report: {
      info: 'Seu desempenho em',
      back: 'Voltar para capítulos',
      redo: 'Refazer tarefa',
      assignmentHits: 'Acertos na tarefa',
      hitsCount: '{count} de {total} questão | {count} de {total} questões',
      rightAnswers: 'acerto | acertos',
      wrongAnswers: 'erro | erros',
      confirm: {
        text: 'Tem certeza de que deseja refazer a tarefa?',
        ok: 'Refazer',
        cancel: 'Cancelar',
      },
      empty: {
        title: 'Ops, não conseguimos preparar seu relatório... ',
        description: 'Ocorreu um problema ao preparar seu relatório. Por favor, tente novamente.',
      },
    },
    empty: {
      title: 'As tarefas estão a caminho!',
      description: 'Em breve serão exibidas aqui.',
      error: {
        title: 'Ocorreu um erro ao mostrar as tarefas da disciplina',
        description: 'Por favor, tente novamente',
      },
    },
    onboarding: {
      1: {
        title: 'Tarefa On-line agora estará no menu Atividades',
        description: 'Novo visual e várias melhorias para você aproveitar o máximo dos seus estudos!',
      },
      2: {
        title: 'Praticando o Enem',
        description: 'Sinta-se mais preparado para o vestibular praticando com questões das últimas edições do Enem',
      },
      3: {
        title: 'Disponível no App SAS Educação ✌',
        description: 'Agora você também pode praticar direto em seu celular ou tablet pelo nosso aplicativo',
      },
    },
  },
  teacher: {
    assignments: {
      title: 'Atividades',
      subtitle: 'Acompanhe o desempenho das suas turmas e seus alunos através dos relatórios das atividades.',
      bookActivities: 'Atividades do livro',
      proposedActivities: 'Atividades propostas',
      extraActivities: 'Atividades extras',
      myActivities: 'Minhas atividades',
      createdByMyself: 'criada por mim',
      createdBy: 'criada por Prof. {name}',
    },
    report: {
      createdBy: 'criado por Prof. {name}',
      hitRate: {
        title: 'Média de acertos',
        count: 'Total de {count} questão | Total de {count} questões',
        empty: 'Nenhum aluno finalizou',
      },
      questionsDrawer: {
        title: 'Questões',
        questionsCount: '{count} questão | {count} questões',
        questionNumber: 'Questão {number}',
        question: 'Questão',
        level: 'Dificuldade',
        content: 'Assunto',
        contents: 'Assuntos',
        skill: 'Habilidade BNCC',
        skillContent: 'Objeto de conhecimento',
        answer: 'Resposta',
        hit: 'Acertou',
        missed: 'Errou',
        seeQuestionProblemsDetails: 'Problema reportado na questão. <strong>Ver detalhes</strong>',
      },
      difficulties: {
        hitRateLong: 'Índice de acertos da questão',
        hits: '{count} de {total} aluno acertou | {count} de {total} alunos acertaram',
      },
    },
    onBoarding: {
      1: {
        title: 'Bem-vindo(a) ao seu Relatório de Atividades',
        description: 'Seu relatório de atividades está repleto de novidades! Avance para saber mais. 😊',
        action: 'Avançar',
      },
      2: {
        title: 'Visão geral da turma no capítulo',
        description: 'Entenda o desempenho da turma com base na média de acertos e no engajamento.',
        action: 'Avançar',
      },
      3: {
        title: 'Dificuldades da turma',
        description: 'Analise as questões mais erradas pela turma e reforce em classe.',
        action: 'Avançar',
      },
      4: {
        title: 'Relatório individual do aluno',
        description: 'Além do desempenho da turma, você pode analisar o desempenho de cada aluno.',
        action: 'Avançar',
      },
      5: {
        title: 'Acesso a todas as questões respondidas',
        description: 'Agora você consegue visualizar todas as questões que o aluno respondeu.',
        action: 'Começar!',
      },
    },
    myActivities: {
      teacherTitle: 'Minhas atividades',
      managerTitle: 'Atividades da escola',
      buttonLabel: 'Criar atividade',
      teacherEmpty: {
        title: 'Nenhuma atividade criada',
        description: 'Você não criou atividades de <strong>{lecture}</strong> para esta turma. Clique em "{buttonLabel}" para começar!',
      },
      managerEmpty: {
        title: 'Nenhuma atividade criada',
        description: 'Nenhuma atividade de <strong>{lecture}</strong> foi criada para esta turma.',
      },
      redirect: {
        title: 'Indo para o Central de Questões',
        description: 'Você será levado para o Central de Questões SAS Plataforma de Educação para escolher suas questões e criar sua atividade!',
      },
      deleteConfirmation: {
        title: 'Quer mesmo excluir a atividade "<strong>{activity}</strong>"?',
        description: 'Ao excluir esta atividade os alunos da turma "<strong>{classroom}</strong>" não poderão mais responder e obter relatórios.',
        warning: 'Os alunos que já responderam a atividade perderão seus dados.',
        cancel: 'Cancelar',
        confirm: 'Excluir atividade',
        success: 'Atividade excluída com sucesso',
      },
    },
    sasActivities: {
      empty: {
        title: 'Ainda não existem atividades SAS Plataforma de Educação para “{lecture}”',
        description: 'Que tal criar uma atividade para sua turma?',
        buttonLabel: 'Criar atividade',
      },
    },
  },
  pages: {
    error: {
      401: {
        title: 'Estamos com dificuldade em identificar o seu acesso.',
        type: 'Erro 401 - Não autorizado.',
        comment: 'Pode ter acontecido algo de errado durante o seu login. Vamos levá-lo de volta ao Portal SAS Plataforma de Educação para se autenticar novamente.',
        backButton: 'Voltar ao Portal SAS Educação',
      },
      403: {
        title: 'Você não tem permissão para visitar esta página.',
        type: 'Erro 403 - Proibido.',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar ao Portal SAS Educação',
      },
      404: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 404 - Página não encontrada',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar para o início',
      },
      500: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 500 - Erro interno do servidor.',
        comment: 'Encontramos uma instabilidade no sistema, mas vai ficar tudo bem. Volte mais tarde, que já estamos trabalhando nisso.',
        backButton: 'Voltar para o início',
      },
      502: {
        title: 'Nosso serviço está temporariamente indisponível.',
        type: 'Erro 502 - Bad gateway.',
        comment: 'Já estamos trabalhando para resolver o problema e não devemos demorar. Tente novamente mais tarde.',
        backButton: 'Voltar para o início',
      },
      503: {
        title: 'Nosso serviço está temporariamente indisponível.',
        type: 'Erro 503 - Serviço indisponível.',
        comment: 'Já estamos trabalhando para resolver o problema e não devemos demorar. Tente novamente mais tarde.',
        backButton: 'Voltar para o início',
      },
    },
  },
  adaptiveSelector: {
    emptyString: 'Nenhum resultado.',
  },
  survey: {
    rateYourExperience: 'Avalie sua experiência',
    wasItEasyToGetHere: 'Foi fácil chegar aqui?',
  },
}

export default ptBR
/* eslint-enable max-len */
