import http from './http'

const QUESTION_STATES = {
  CORRECT: 'correct',
  WRONG: 'wrong',
  NOT_ANSWERED: 'not_answered',
}

export {
  QUESTION_STATES,
}

export default {
  fetchQuestion: ({
    questionnaireCode,
    assignmentCode,
    questionId,
  }) => {
    if (questionnaireCode) {
      return http.get(
        `/questionnaires/${questionnaireCode}/assignments`
      )
    }
    if (assignmentCode && questionId) {
      return http.get(
        `/assignments/${assignmentCode}/questions/${questionId}`
      )
    }
    if (assignmentCode) {
      return http.get(`assignments/${assignmentCode}/questions`)
    }

    return Promise.reject()
  },
  fetchQuestions: async ({ questionnaireCode }) => {
    let response = {
      data: {},
    }
    if (questionnaireCode) {
      response = await http.get(
        `/questionnaires/${questionnaireCode}/questions`
      )
    }

    return response.data
  },
  fetchPageFlips: async (questionnaireCode) => {
    const response = await http.get(
      `questionnaires/${questionnaireCode}/pageflips`
    )

    return response.data
  },
  fetchVideo: (questionnaireCode) => (
    http.get(`questionnaires/${questionnaireCode}/videos`)
  ),
  postAnswer: async ({
    assignmentCode,
    questionId,
    markedOption,
  }) => {
    const response = await http.post(
      `assignments/${assignmentCode}/answers`,
      { questionId, markedOption }
    )

    return response.data
  },
  fetchAnsweredQuestions: async (assignmentCode, classroomId) => {
    const queryParams = classroomId ? `?classroomId=${classroomId}` : ''
    const response = await http.get(
      `/assignments/${assignmentCode}/answered-questions${queryParams}`
    )

    return response.data
  },
  fetchAnsweredQuestion: async (questionId, assignmentCode) => {
    let response = {
      data: {},
    }
    if (assignmentCode) {
      response = await http.get(
        `/answered-questions/${questionId}?assignmentCode=${assignmentCode}`
      )
    } else {
      response = await http.get(
        `/answered-questions/${questionId}`
      )
    }

    return response.data
  },
}
