import urls from '@/utils/urls'
import http from './http'

const config = { baseURL: urls.AUTH_API }
const configV2 = { baseURL: urls.AUTH_API_V2 }

export default {
  getUser: () => http.get('users/me', config),
  getPersonalData: () => http.get('personal-data', configV2),
  getSignedIn: () => http.get('signin'),
}
