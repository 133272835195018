import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  question: {
    loading: false,
    data: null,
    error: null,
  },
  questions: {
    loading: false,
    data: {},
    error: null,
  },
  answer: {
    loading: false,
    data: {},
    error: null,
  },
  performance: {
    loading: false,
    data: {},
    error: null,
  },
  rewards: {
    loading: false,
    data: null,
    error: null,
  },
  progress: {
    loading: false,
    data: {},
    error: null,
  },
  tip: {
    loading: false,
    data: {},
    error: null,
  },
  video: {
    loading: false,
    data: {},
    error: null,
  },
  pageflip: {
    loading: false,
    data: {},
    error: null,
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
